import * as React from "react"
import { Link, graphql } from "gatsby"
import FAQCard from "../../components/FAQCard"
import SymptomsCard from "../../components/SymptomsCard"
import AdmissionGreetingCard from "../../components/AdmissionGreetingCard"

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";

const ForMePage = ({ data, pageContext }) => (
  <Layout pageTitle="脊科治療適合我嗎？" context={pageContext} openGraph={{
    url: pageContext.localizedUrls[pageContext.locale],
    title: pageContext.title,
    description: pageContext.description,
    images: [
      {
        url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
      }
    ]
  }}>
    <PageHeader
      title={data.firestore.page.content[pageContext.locale].headerTitle}
      imageUrl={data.firestore.page.content.headerImage}
    />
    <main className="my-12 flex flex-col lg:flex-row justify-evenly w-full px-4">
      <section className="text-coconut mb-16 lg:mb-0">
        <h1 className="text-center text-coconut font-semibold text-xl lg:text-2xl mb-4">{data.firestore.page.content[pageContext.locale].heading}</h1>
        <div className="prose-sm lg:prose">
          {
            data.firestore.page.content[pageContext.locale].beneficiaries.map(item => (
              <div className="flex items-center max-w-3xl" key={item.href}>
                <img className="rounded-full h-32 w-32 lg:h-40 lg:w-40" src={item.image} alt={item.title}/>
                <div className="flex flex-col ml-4 lg:ml-8">
                  <h3 className="font-semibold text-mango text-base lg:text-2xl">
                    <Link to={item.href}>
                      { item.title }
                    </Link>
                  </h3>
                  <p className="text-base lg:text-lg">{item.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </section>
      <aside className="items-center flex flex-col space-y-6 lg:items-start">
        <AdmissionGreetingCard/>
        <SymptomsCard/>
        <FAQCard/>
      </aside>
    </main>
  </Layout>
)

export default ForMePage

export const query = graphql`
  query ForMePageQuery($pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
    }
  }
`